import Vue from 'vue'

export interface PatientAddCheckResponse {
  patientNumberAvailable: boolean
  reason: string
}

export default {
  getPatientList(siteNo: string) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patientlist', { params: { siteNumber: siteNo } })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getPatientList error', error, {
            siteNo,
          })
          reject(error)
        })
    })
  },

  checkPatientNo(siteNo: string, patientNo: string) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/check', {
          params: { siteNumber: siteNo, patientNumber: patientNo },
        })
        .then(response => resolve(response.data as PatientAddCheckResponse))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('checkPatientNo error', error, {
            siteNo,
            patientNo,
          })
          reject(error)
        })
    })
  },
  createPatient(data) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .post('/web/patient', data)
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('createPatient error', error, {
            data,
          })
          reject(error)
        })
    })
  },
}
