<template>
  <v-dialog
    v-model="show"
    :max-width="540"
    persistent
    data-testid="archive-dialog"
  >
    <template #activator="{ on }">
      <v-btn
        rounded
        color="secondary"
        @click="openRequestDialog"
        class="elevation-1"
        v-on="on"
        data-testid="archive-dialog-activator"
      >
        Request archive
      </v-btn>
    </template>

    <DialogCard @closeDialog="show = false">
      <template #title> Confirm data archiving </template>
      <template #actions>
        <v-btn
          rounded
          depressed
          @click.stop="show = false"
          class="mr-4 elevation-1"
          data-testid="archive-dialog-cancel"
          >Cancel</v-btn
        >
        <v-btn
          color="secondary"
          rounded
          depressed
          @click="onRequestArchive"
          :loading="isLoading"
          class="elevation-1"
          data-testid="archive-dialog-confirm"
          >Confirm</v-btn
        >
      </template>
      <div>
        <strong>Trial number: </strong>{{ trialNo }}
        <br />
        <strong>Site number: </strong>{{ siteNo }}
        <br />
        <strong>Number of patients: </strong>
        <v-skeleton-loader
          type="text"
          :loading="isLoading"
          class="text-loader"
          :class="{ middle: isLoading }"
        >
          {{ numberOfPatients }}
        </v-skeleton-loader>
        <p class="my-2">
          You are about to generate a data archive file containing data from all
          your patients.
        </p>
      </div>
    </DialogCard>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import DialogCard from '@/components/DialogCard'
import { notificationMapActions } from '@/store/modules/notificationModule'
import loadingState from '@/constants/loadingState'
import NotificationType from '@/store/modules/notification/NotificationType'
import archiveService from '@/services/site-archive-service'
import patientService from '@/services/site-patient-service'

export default {
  name: 'RequestArchive',
  components: { DialogCard },
  props: {
    siteNo: { type: [String, Number], required: true },
    trialNo: { type: [String, Number], required: true },
  },
  data() {
    return {
      show: false,
      state: loadingState.INITIAL,
      numberOfPatients: null,
    }
  },
  computed: {
    isLoading() {
      return this.state === loadingState.LOADING
    },
  },
  methods: {
    ...notificationMapActions(),
    onRequestArchive() {
      this.state = loadingState.LOADING

      archiveService
        .requestArchive(this.siteNo)
        .then(() => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.$emit('new-archive-requested')
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)

          this.spawnNotification({
            type: NotificationType.Error,
            title: 'Archive request failed.',
          })
        })
        .finally(() => {
          this.show = false
        })
    },

    fetchPatientList() {
      this.state = loadingState.LOADING
      patientService
        .getPatientList(this.siteNo)
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.numberOfPatients = response.data.length
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },

    openRequestDialog() {
      this.show = true
      this.fetchPatientList()
    },
  },
}
</script>

<style lang="scss" scoped>
.text-loader {
  display: inline-block;
  width: 30px;
}
.middle {
  vertical-align: middle !important;
}
</style>
