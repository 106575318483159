import Vue from 'vue'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'

export default {
  requestArchive(siteNo: string) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .post(`/web/sites/${siteNo}/archives`, { currentClientTime })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('requestArchive error', error, {
            siteNo,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  getArchives(siteNo: string) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .get(`/web/sites/${siteNo}/archives`)
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getArchives error', error, {
            siteNo,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  downloadArchive(siteNo: string, archiveId: number) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .post(
          '/web/sites/' + siteNo + '/archives/' + archiveId,
          { currentClientTime },
          { responseType: 'arraybuffer' }
        )
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('downloadArchive error', error, {
            siteNo,
          })
          reject(error)
        })
    })
  },
}
