<template>
  <v-dialog
    v-model="show"
    :max-width="540"
    persistent
    data-testid="download-archive-dialog"
  >
    <template #activator="{ on }">
      <v-btn
        :disabled="disabled"
        outlined
        rounded
        small
        @click="show = true"
        v-on="on"
        data-testid="download-archive-dialog-activator"
      >
        <v-icon class="mr-2" small>mdi-arrow-collapse-down</v-icon>
        Download
      </v-btn>
    </template>

    <DialogCard @closeDialog="show = false">
      <template #title> Confirm downloading data archive </template>
      <template #actions>
        <v-btn
          rounded
          depressed
          @click.stop="show = false"
          class="mr-4 elevation-1"
          data-testid="download-archive-dialog-cancel"
          >Cancel</v-btn
        >
        <v-btn
          color="secondary"
          rounded
          depressed
          @click="onDownloadArchive"
          :loading="isLoading"
          class="elevation-1"
          data-testid="download-archive-dialog-confirm"
          >Confirm</v-btn
        >
      </template>
      <DateFormat :value="item.requestedAt.time" />
      <br />
      <strong>{{ item.zipFileName }}</strong> ({{ item.zipFileSize }})
      <p class="my-2">
        You are about to download a data archive file containing data from all
        your patients.
      </p>
    </DialogCard>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import DateFormat from '@/components/DateFormat.vue'
import DialogCard from '@/components/DialogCard'
import archiveService from '@/services/site-archive-service'
import { notificationMapActions } from '@/store/modules/notificationModule'
import loadingState from '@/constants/loadingState'
import NotificationType from '@/store/modules/notification/NotificationType'

export default {
  name: 'DownloadArchive',
  components: { DateFormat, DialogCard },
  props: {
    disabled: { type: Boolean, required: true },
    item: { type: Object, required: true },
    siteNo: { type: [String, Number], required: true },
  },
  data() {
    return {
      show: false,
      state: loadingState.INITIAL,
    }
  },
  computed: {
    isLoading() {
      return this.state === loadingState.LOADING
    },
  },
  methods: {
    ...notificationMapActions(),
    onDownloadArchive() {
      this.state = loadingState.LOADING

      archiveService
        .downloadArchive(this.siteNo, this.item.archiveId)
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED

          let blob = new Blob([response], ['octet/stream'])
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.download = this.item.zipFileName
          link.click()
          URL.revokeObjectURL(link.href)

          this.$emit('archive-downloaded')
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)

          this.spawnNotification({
            type: NotificationType.Error,
            title: 'Archive download failed.',
          })
        })
        .finally(() => {
          this.show = false
        })
    },
  },
}
</script>
